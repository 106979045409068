import axios from 'axios'
export const getDictType = params => axios.get(`/web/dicttype/type/${params}`) // 字典类型
export const getConfigType = params => axios.get(`/web/dicttype/configKey/${params}`) // 参数类型
export const getBannerList = params => axios.get(`/web/category/getBannerList?type=${params.type}`) // 获取banner列表
export const getHomeVideoList = () => axios.get('/web/category/getHomeVideoList') // 获取课程列表
export const selectVideoAllList = params => axios.get(`/web/category/selectVideoAllList?searchValue=${params.searchVal}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`)
export const postFeedback = params => axios.post('/web/feedback/addFeedBack', params)

export const getAuthorization = params => axios.get(`/cas?ticket=${params}`) // 参数类型
export const getApp = params => axios.get('/biz/app/latest', { params: {...params } })

export const getSChoolList= params => axios.get(`/biz/article/list?type1=3&pageNum=${params.pageNum}&pageSize=${params.pageSize}`) // 参数类型